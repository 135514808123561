import { TowStatus } from '@enums/tow-status.enum';
import { GeoData } from '@models/geo-data.model';
import { Tow } from '@models/tow.model';
import { createAction, props } from '@ngrx/store';

// Get Tows
export const getTowsRequest = createAction(
    '[Tow] Get Tows Request',
    props<{ userId?: string, statuses?: TowStatus[], driverId?: string }>()
);
export const getTowsFailure = createAction(
    '[Tow] Get Tows Failure',
    props<{ error: string }>()
);
export const getTowsSuccess = createAction(
    '[Tow] Get Tows Success',
    props<{ tows: Tow[] }>()
);

// Get Tows Near By
export const getTowsNearByRequest = createAction(
    '[Tow] Get Tows Near by Request',
    props<{ center: GeoData, radius: number, excludeUserId: string, includeStatuses?: TowStatus[] }>()
);
export const getTowsNearByFailure = createAction(
    '[Tow] Get Tows Near by Failure',
    props<{ error: string }>()
);
export const getTowsNearBySuccess = createAction(
    '[Tow] Get Tows Near by Success',
    props<{ tows: Tow[] }>()
);

// Get Tow
export const getTowRequest = createAction(
    '[Tow] Get Tow Request',
    props<{ id: string }>()
);
export const getTowFailure = createAction(
    '[Tow] Get Tow Failure',
    props<{ error: string }>()
);
export const getTowSuccess = createAction(
    '[Tow] Get Tow Success',
    props<{ tow: Tow }>()
);

// Get Tow Changes
export const getTowChangesRequest = createAction(
    '[Tow] Get Tow Changes Request',
    props<{ id: string }>()
);
export const getTowChangesLoaded = createAction(
    '[Route] Get Tow Changes Loaded'
);
export const getTowChangesSuccess = createAction(
    '[Tow] Get Tow Changes Success',
    props<{ tow: Tow }>()
);

// Save Tow
export const saveTowRequest = createAction(
    '[Tow] Save Tow Request',
    props<{ tow: Tow }>()
);
export const saveTowFailure = createAction(
    '[Tow] Save Tow Failure',
    props<{ error: string }>()
);
export const saveTowSuccess = createAction(
    '[Tow] Save Tow Success',
    props<{ tow: Tow }>()
);

// Delete Tow
export const deleteTowRequest = createAction(
    '[Tow] Delete Tow Request',
    props<{ tow: Tow }>()
);
export const deleteTowFailure = createAction(
    '[Tow] Delete Tow Failure',
    props<{ error: string }>()
);
export const deleteTowSuccess = createAction(
    '[Tow] Delete Tow Success',
    props<{ id: string }>()
);

// Clear Tows
export const clearTows = createAction(
    '[Tow] Clear Tows'
);

// Unsubscribe Tow Changes
export const unsubscribeTowChangesRequest = createAction(
    '[Tow] Unsubscribe Tow Changes Request'
);
export const unsubscribeTowChangesSuccess = createAction(
    '[Tow] Unsubscribe Tow Changes Success'
);

// Update Tow Properties
export const updateTowPropertiesRequest = createAction(
    '[Tow] Update Tow Properties Request',
    props<{ id: string, properties: Partial<Tow> }>()
);
export const updateTowPropertiesFailure = createAction(
    '[Tow] Update Tow Properties Failure',
    props<{ error: string }>()
);
export const updateTowPropertiesSuccess = createAction(
    '[Tow] Update Tow Properties Success',
    props<{ partialTow: Partial<Tow> }>()
);

// Get Tow Receipt
export const getTowReceiptRequest = createAction(
    '[Tow] Get Tow Receipt Request',
    props<{ id: string }>()
);
export const getTowReceiptFailure = createAction(
    '[Tow] Get Tow Receipt Failure',
    props<{ error: string }>()
);
export const getTowReceiptSuccess = createAction(
    '[Tow] Get Tow Receipt Success',
    props<{ id: string, blob: Blob }>()
);
