<button *ngIf="showToggle" type="button" (click)="onToggleDriverDetails()" [class.hidden]="hide">
    <img class="up" src="/assets/svg/up-arrow.svg">
    <img class="down" src="/assets/svg/down-arrow.svg">
</button>

<div *ngIf="driverDetails" class="cols">
    <div class="left">
        <div class="name">{{ driverDetails.user.firstName }} {{ driverDetails.user.lastName }}</div>
        <div class="rating">
            <app-no-rating *ngIf="!driverDetails.user.driver?.averageRating; else ratingComponent"></app-no-rating>
            <ng-template #ratingComponent>
                <app-rating *ngIf="driverDetails.user.driver?.averageRating" [rating]="driverDetails.user.driver?.averageRating ?? 0"></app-rating>
            </ng-template>
            @if (driverDetails.user.driver?.averageRating ?? 0 > 0) {
                <div class="link" (click)="onViewRatings()">{{ driverDetails.user.driver?.averageRating | number:'1.0-2' }}</div>
            } @else {
                <div>0</div>
            }
        </div>
        <div class="tows">Completed Tows: <span>{{ driverDetails.user.driver?.completedTows | number }}</span></div>
        <!-- <img class="icon" [src]="'/assets/svg/vehicle-' + driverDetails.vehicle.icon + '.svg'"><br> -->
        <ion-icon class="icon" [src]="'/assets/svg/vehicle-' + driverDetails.vehicle.icon + '.svg'"></ion-icon>
        <app-vehicle-policy [vehicle]="driverDetails.vehicle"></app-vehicle-policy>
        {{ driverDetails.vehicle | vehicleSummary }}<br>
        {{ driverDetails.vehicle.licensePlate }} {{ driverDetails.vehicle.licensePlateState }}<br>
        {{ driverDetails.vehicle.driveType }} {{ driverDetails.vehicle.engine }} {{ driverDetails.vehicle.engineSize }}<br>
        <strong>TWA {{ driverDetails.vehicle.towingCapacity | number }} lbs</strong>
    </div>
    <div class="right">
        <!-- For some reason the *ngIf/else approach sometimes causes both, the avatar and, icon, to show up. So let's use *nfIf on both instead -->
        <ion-avatar *ngIf="!displayDefaultDriverPhoto" [class.online]="driverDetails.user.driver?.active">
            <img [src]="driverDetails.user.photo" (error)="onDriverPhotoError()" referrerpolicy="no-referrer">
        </ion-avatar>
        <mat-icon *ngIf="displayDefaultDriverPhoto" [class.online]="driverDetails.user.driver?.active">person</mat-icon>
        <ng-container *ngIf="driverDetails.payment.cost; else costPerMile">
            <div>
                <div class="cost success">
                    <span>$</span>{{ driverDetails.payment.cost | number:'1.2-2' }}
                </div>
                <div class="miles-away" [style.color]="milesAwayColor">
                    {{ driverDetails.milesAway }} mi away
                </div>
            </div>
            <div class="btn">
                <ion-button *ngIf="status === undefined; else statusBtn" color="primary" size="default" (click)="onSendTowRequest()">
                    Send Request
                </ion-button>
                <ng-template #statusBtn>
                    <ion-button class="status" [fill]="btnFill" [color]="btnColor" size="default">
                        {{ status }}
                    </ion-button>
                    <div *ngIf="towRequest?.status === 0" class="link" (click)="onCancelTowRequest()">
                        Cancel
                    </div>
                </ng-template>
            </div>
        </ng-container>
        <ng-template #costPerMile>
            {{ driverDetails.costPerMile | currency }}/mi
        </ng-template>
    </div>
</div>
