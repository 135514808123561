import { Injectable } from '@angular/core';
import { Tow } from '@models/tow.model';
import { combineLatest, map, Observable, Subject } from 'rxjs';
import { DbService } from './db.service';
import { Filter } from '@models/filter.model';
import { Sort } from '@models/sort.model';
import { TowStatus } from '@enums/tow-status.enum';
import { ObjectHelper } from '@classes/object-helper';
import { Unsubscribe } from '@angular/fire/firestore';
import { GeoData } from '@models/geo-data.model';
import { GeoHelper } from '@classes/geo-helper';
import { RouteData } from '@models/route-data.model';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';

const COLLECTION = 'tows';

@Injectable({
    providedIn: 'root'
})
export class TowService {
    constructor(
        private http: HttpClient,
        private dbService: DbService
    ) {}

    getTows(userId?: string, statuses?: TowStatus[], driverId?: string): Observable<Tow[]> {
        const filters: Filter[] = [];

        if (userId) {
            filters.push({ field: 'user.id', operator: '==', value: userId });
        }

        if (statuses) {
            filters.push({ field: 'status', operator: 'in', value: statuses });
        }

        if (driverId) {
            filters.push({ field: 'approvedRequest.driverDetails.user.id', operator: '==', value: driverId });
        }

        const sort: Sort = { field: 'creationDate', direction: 'desc' };
        return this.dbService.getList<Tow>(COLLECTION, filters, sort).pipe(
            map(tows => {
                tows.forEach(tow => {
                    this.fixDates(tow);
                });
                return tows;
            })
        );
    }

    getTowsNearBy(center: GeoData, radius: number, excludeUserId: string, includeStatuses?: TowStatus[]): Observable<Tow[]> {
        const latitude = center.latitude;
        const longitude = center.longitude;

        // Each item in 'bounds' represents a startAt/endAt pair. We'll issue a separate query for each pair
        const bounds = GeoHelper.getNearGeohashes(latitude, longitude, radius);
        if (bounds.length > 0) {
            const tows$: Observable<Tow[]>[] = [];
            bounds.forEach(bound => {
                const sort: Sort = { field: 'routeData.start.geohash', direction: 'asc' };
                const list$ = this.dbService.getList<Tow>(COLLECTION, undefined, sort, bound[0], bound[1]);
                tows$.push(list$);
            });
            return combineLatest(tows$).pipe(
                map(tows => {
                    const matchedTows: Tow[] = [];
                    const list = tows.flat();
                    list.forEach(tow => {
                        // Skip excludeUserId
                        if (tow.user.id === excludeUserId) {
                            return;
                        }

                        // Let's filter out the false positives due to geohash accuracy
                        const isLocationInRadius = GeoHelper.isLocationInRadius(center, tow.routeData.start, radius);
                        if (!isLocationInRadius) {
                            return;
                        }

                        // Skip tows that don't have a status found in includeStatuses
                        if (includeStatuses && !includeStatuses.includes(tow.status)) {
                            return;
                        }

                        this.fixDates(tow);
                        matchedTows.push(tow);
                    });

                    return matchedTows;
                })
            );
        }

        const filters: Filter[] = [];
        if (excludeUserId) {
            filters.push({ field: 'user.id', operator: '!=', value: excludeUserId });
        }

        if (includeStatuses) {
            filters.push({ field: 'status', operator: 'in', value: includeStatuses });
        }

        return this.dbService.getList<Tow>(COLLECTION, filters).pipe(
            map(tows => {
                tows.forEach(tow => {
                    this.fixDates(tow);
                });
                return tows;
            })
        );
    }

    getTow(id: string): Observable<Tow> {
        return this.dbService.getObj<Tow>(COLLECTION, id).pipe(
            map(tow => {
                this.fixDates(tow);
                return tow;
            })
        );
    }

    getTowChanges(id: string, sub$: Subject<Tow>): Unsubscribe {
        const otherFixes = (tow: Tow) => {
            this.fixDates(tow);
        };
        return this.dbService.getObjChanges(COLLECTION, id, sub$, otherFixes);
    }

    saveTow(tow: Tow): Observable<Tow> {
        tow = this.setGeohash(tow);
        return this.dbService.saveObj(COLLECTION, tow);
    }

    deleteTow(tow: Tow): Observable<string> {
        return this.dbService.deleteObj(COLLECTION, tow.id);
    }

    updateTowProperties(id: string, properties: Partial<Tow>): Observable<Partial<Tow>> {
        return this.dbService.updateObjProperties(COLLECTION, id, properties);
    }

    getTowReceipt(id: string): Observable<Blob> {
        const url = `${environment.apiBaseUrl}/tows/${id}/receipt`;
        return this.http.get(url, { responseType: 'blob' });
    }

    private setGeohash(tow: Tow): Tow {
        let startGeohash: string | null = null;
        let destinationGeohash: string | null = null;
        if (!tow.routeData.start.geohash) {
            const geohash = GeoHelper.getGeohash(tow.routeData.start.latitude, tow.routeData.start.longitude);
            startGeohash = geohash;
        }

        if (!tow.routeData.destination.geohash) {
            const geohash = GeoHelper.getGeohash(tow.routeData.destination.latitude, tow.routeData.destination.longitude);
            destinationGeohash = geohash;
        }

        const start: GeoData = startGeohash ?
            { ...tow.routeData.start, geohash: startGeohash } :
            tow.routeData.start;
        const destination: GeoData = destinationGeohash ?
            { ...tow.routeData.destination, geohash: destinationGeohash } :
            tow.routeData.destination;
        const routeData: RouteData = { ...tow.routeData, start, destination };
        return { ...tow, routeData };
    }

    private fixDates(tow: Tow): void {
        ['datetime', 'cancellationDate'].forEach(property => {
            ObjectHelper.fixDate(tow, property);
        });
    }
}
