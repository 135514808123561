import { adapter, initialState, State } from './state';
import { createReducer, on, Action } from '@ngrx/store';
import { clearTows, deleteTowFailure, deleteTowRequest, deleteTowSuccess, getTowChangesLoaded, getTowChangesRequest,
    getTowChangesSuccess, getTowFailure, getTowReceiptFailure, getTowReceiptRequest, getTowReceiptSuccess, getTowRequest,
    getTowsFailure, getTowsNearByFailure, getTowsNearByRequest, getTowsNearBySuccess, getTowsRequest, getTowsSuccess,
    getTowSuccess, saveTowFailure, saveTowRequest, saveTowSuccess, updateTowPropertiesSuccess } from './actions';
import { ObjectHelper } from '@classes/object-helper';
import { Tow } from '@models/tow.model';

export const towReducer = createReducer(
    initialState,
    on(
        getTowsRequest,
        getTowsNearByRequest,
        getTowRequest,
        getTowChangesRequest,
        saveTowRequest,
        deleteTowRequest,
        getTowReceiptRequest,
        state => ({
            ...state,
            loading: true,
            error: null
        })
    ),
    on(
        getTowsFailure,
        getTowsNearByFailure,
        getTowFailure,
        saveTowFailure,
        deleteTowFailure,
        getTowReceiptFailure,
        (state, { error }) => ({
            ...state,
            loading: false,
            error
        })
    ),
    on(
        getTowChangesLoaded,
        getTowReceiptSuccess,
        (state) => ({
            ...state,
            loading: false
        })
    ),

    // Get Tows/Near by
    on(
        getTowsSuccess,
        getTowsNearBySuccess,
        (state, { tows }) => adapter.upsertMany(tows, {
            ...state,
            loading: false,
            error: null,
            total: tows.length
        })
    ),

    // Get/Save Tow/Changes
    on(
        getTowSuccess,
        getTowChangesSuccess,
        saveTowSuccess,
        (state, { tow }) => adapter.upsertOne(tow, {
            ...state,
            loading: false,
            error: null
        })
    ),

    // Delete Tow
    on(
        deleteTowSuccess,
        (state, { id }) => adapter.removeOne(id, {
            ...state,
            loading: false,
            error: null
        })
    ),

    // Clear Tows
    on(
        clearTows,
        (state) => adapter.removeAll({
            ...state,
            loading: false,
            error: null,
            total: 0
        })
    ),

    // Update Tow Properties
    on(
        updateTowPropertiesSuccess,
        (state, { partialTow }) => {
            const tow = state.entities[partialTow.id!]!;
            const updatedTow = ObjectHelper.mergeObject(tow, partialTow as Tow);
            return adapter.upsertOne(updatedTow, {
                ...state,
                loading: false,
                error: null
            });
        }
    )
);

export function reducer(state: State, action: Action) {
    return towReducer(state, action);
}
