import { Injectable } from '@angular/core';
import { Vehicle } from '@models/vehicle.model';
import { catchError, combineLatest, map, Observable, of, switchMap, tap } from 'rxjs';
import { FileService } from './file.service';
import { DbService } from './db.service';
import { Filter } from '@models/filter.model';
import { Sort } from '@models/sort.model';
import { environment } from '@environments/environment';
import { ListHelper } from '@classes/list-helper';
import { ObjectHelper } from '@classes/object-helper';
import { VINDecoderData } from '@models/vin-decoder-data.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AllowedVehicleService } from './allowed-vehicle.service';
import { AllowedVehicle } from '@models/allowed-vehicle.model';
import { Cache } from '@classes/cache';

const COLLECTION = 'vehicles';
const IMAGES_FOLDER = 'images/vehicle';

@Injectable({
    providedIn: 'root'
})
export class VehicleService {
    constructor(
        private fileService: FileService,
        private dbService: DbService,
        private http: HttpClient,
        private allowedVehicleService: AllowedVehicleService
    ) {}

    getVehicles(userId: string): Observable<Vehicle[]> {
        const filters: Filter[] = [{ field: 'userId', operator: '==', value: userId }];
        const sort: Sort = { field: 'creationDate', direction: 'desc' };
        return this.dbService.getList<Vehicle>(COLLECTION, filters, sort).pipe(
            map(vehicles => {
                vehicles.forEach(vehicle => {
                    this.fixDates(vehicle);
                });
                return vehicles;
            })
        );
    }

    getVehicle(id: string): Observable<Vehicle> {
        return this.dbService.getObj<Vehicle>(COLLECTION, id).pipe(
            map(vehicle => {
                this.fixDates(vehicle);
                return vehicle;
            })
        );
    }

    getActiveVehicles(userIds: string[]): Observable<Vehicle[]> {
        if (userIds.length === 0) {
            return of([]);
        }

        const commonFilters: Filter[] = [
            { field: 'active', operator: '==', value: true }
        ];
        const vehiclesList$: Observable<Vehicle[]>[] = [];
        const userIdsList = ListHelper.splitListIntoChunks(userIds, environment.firebaseInClauseLimit);
        userIdsList.forEach(value => {
            const filters = [...commonFilters];
            filters.push({ field: 'userId', operator: 'in', value });
            const vehicles$ = this.dbService.getList<Vehicle>(COLLECTION, filters);
            vehiclesList$.push(vehicles$);
        });
        return combineLatest(vehiclesList$).pipe(
            map(vehiclesList => {
                const vehicles = vehiclesList.flat();
                vehicles.forEach(vehicle => {
                    this.fixDates(vehicle);
                });
                return vehicles;
            })
        );
    }

    getActiveVehicle(userId: string): Observable<Vehicle> {
        const filters: Filter[] = [
            { field: 'userId', operator: '==', value: userId },
            { field: 'active', operator: '==', value: true }
        ];
        return this.dbService.getList<Vehicle>(COLLECTION, filters).pipe(
            map(x => x[0]),
            map(vehicle => {
                this.fixDates(vehicle);
                return vehicle;
            })
        );
    }

    saveVehicle(vehicle: Vehicle): Observable<Vehicle> {
        // If they have supplied a photo, upload it
        if (vehicle.registrationPhotoFile || vehicle.insurancePhotoFile) {
            const rootFileName = `${vehicle.year}-${vehicle.make}-${vehicle.model}`;
            const registrationFileName = `${rootFileName}-registration`;
            const insuranceFileName = `${rootFileName}-insurance`;
            const registrationPhoto$ = vehicle.registrationPhotoFile ?
                this.fileService.uploadFile(IMAGES_FOLDER, vehicle.registrationPhotoFile, registrationFileName) :
                of(vehicle.registrationPhoto);
            const insurancePhoto$ = vehicle.insurancePhotoFile ?
                this.fileService.uploadFile(IMAGES_FOLDER, vehicle.insurancePhotoFile, insuranceFileName) :
                of(vehicle.insurancePhoto);
            const save$ = combineLatest([registrationPhoto$, insurancePhoto$]).pipe(
                switchMap(([registrationPhoto, insurancePhoto]) => this.save({ ...vehicle,
                    registrationPhoto: registrationPhoto,
                    registrationPhotoFile: undefined,
                    insurancePhoto: insurancePhoto,
                    insurancePhotoFile: undefined
                }))
            );

            // Delete the previous photo(s) (if any)
            if ((vehicle.registrationPhoto && vehicle.registrationPhotoFile) ||
                (vehicle.insurancePhoto && vehicle.insurancePhotoFile)) {
                const registrationName = vehicle.registrationPhotoFile ?
                    this.fileService.getFileName(vehicle.registrationPhoto, IMAGES_FOLDER) :
                    null;
                const deleteFrontFile$ = registrationName ? this.fileService.deleteFile(IMAGES_FOLDER, registrationName).pipe(
                    catchError(() => of([])) // Don't worry if the deletion fails
                ) : of('');
                const insuranceName = vehicle.insurancePhotoFile ?
                    this.fileService.getFileName(vehicle.insurancePhoto, IMAGES_FOLDER) :
                    null;
                const deleteBackFile$ = insuranceName ? this.fileService.deleteFile(IMAGES_FOLDER, insuranceName).pipe(
                    catchError(() => of([])) // Don't worry if the deletion fails
                ) : of('');
                return combineLatest([save$, deleteFrontFile$, deleteBackFile$]).pipe(
                    map(([save]) => save)
                );
            }

            return save$;
        }

        return this.save(vehicle);
    }

    deleteVehicle(vehicle: Vehicle): Observable<string> {
        const delete$ = this.dbService.deleteObj(COLLECTION, vehicle.id);

        // Delete the registration photo (if any)
        if (vehicle.registrationPhoto) {
            const name = this.fileService.getFileName(vehicle.registrationPhoto, IMAGES_FOLDER);
            const deleteFile$ = this.fileService.deleteFile(IMAGES_FOLDER, name).pipe(
                catchError(() => of([])) // Don't worry if the deletion fails
            );
            return combineLatest([delete$, deleteFile$]).pipe(
                map(([id]) => id)
            );
        }

        return delete$;
    }

    updateVehicles(vehicles: Vehicle[]): Observable<Vehicle[]> {
        return this.dbService.updateList(COLLECTION, vehicles);
    }

    decodeVIN(vin: string): Observable<VINDecoderData> {
        // return of({ year: 2024, make: 'Toyota', model: 'Tundra' }); // Testing
        // return of({ year: 2021, make: 'Nissan', model: 'Titan' }); // Testing
        // return of({ year: 2021, make: 'Nissan' }); // Testing
        // return of({ year: 2021 }); // Testing
        // return of({ year: 0 }); // Testing
        // 1FTEW1E54JFB30392
        // 1GCUDDED9RZ129873
        // 1C6SRFFT2MN586025
        // 3GTU9DED5MG370968
        // 1C6HJTFG9ML572069
        // 1N6AD0ER4GN721965
        // 1N6AA1EE3MN528567
        // 5TFLA5DB1RX213322 - Toyota Tundra 2024
        // 5TFLA5AB2RX039331 - Toyota Tundra 2024
        // 3TMKB5FN6RM014224 - Toyota Tacoma 2024
        // 1FT8W2BM2REC24884 - Ford F-250 2024
        vin = vin.trim().toUpperCase();
        const cacheKey = `VIN:${vin}`;
        const cache = Cache.get<VINDecoderData>(cacheKey);
        if (cache) {
            return of(cache);
        }

        const url = `https://${environment.rapidApis.vinDecoder.host}${environment.rapidApis.vinDecoder.route}${vin}`;
        const options = {
            headers: new HttpHeaders()
                .set('X-RapidAPI-Key', environment.rapidApis.key)
                .set('X-RapidAPI-Host', environment.rapidApis.vinDecoder.host)
        };
        return this.http.get(url, options).pipe(
            map((response: any) => {
                const errorArr: string[] = response.errorText?.split(' - ');
                const [ code, error ] = errorArr;
                const errorCode = +code;
                if (errorCode === 8) {
                    return { year: 0 };
                }

                if (errorCode) {
                    throw new Error(error);
                }

                return {
                    year: +response.year,
                    make: `${response.manufacturedBy}`,
                    model: `${response.model}`
                };
            }),
            switchMap(data => {
                const make = this.getMakeFromVINDecoderData(data);
                const allowedVehicles$ = this.allowedVehicleService.getAllowedVehicles(data.year, make);
                return combineLatest([of(data), allowedVehicles$]);
            }),
            map(([data, allowedVehicles]) => {
                const { year } = data;
                const make = this.getMake(data, allowedVehicles);
                const model = this.getModel(data, allowedVehicles, make);
                return { year, make, model };
            }),
            tap(data => Cache.add(cacheKey, data))
        );
    }

    isVINFound(vin: string): Observable<boolean> {
        const filters: Filter[] = [{ field: 'vin', operator: '==', value: vin }];
        return this.dbService.getCount(COLLECTION, filters).pipe(
            map(count => count > 0)
        );
    }

    private save(vehicle: Vehicle): Observable<Vehicle> {
        return this.dbService.saveObj(COLLECTION, vehicle);
    }

    private fixDates(vehicle: Vehicle): void {
        ObjectHelper.fixDate(vehicle, 'registrationExpDate');
        ObjectHelper.fixDate(vehicle, 'insuranceExpDate');
    }

    private getMakeFromVINDecoderData(data: VINDecoderData): string | undefined {
        const make = data.make?.toUpperCase();
        switch (make) {
            // Honda
            case 'AMERICAN HONDA MOTOR CO., INC.':
            case 'HONDA MOTOR CO., LTD.':
            case 'HONDA OF AMERICA MFG., INC.':
            case 'HONDA MANUFACTURING OF ALABAMA, LLC':
            case 'HONDA MOTOR COMPANY (CANADA)':
                return 'Honda';
            // Toyota
            case 'TOYOTA MOTOR CORPORATION':
            case 'TOYOTA MOTOR MANUFACTURING, TEXAS, INC.':
            case 'TOYOTA MOTOR MANUFACTURING, CANADA, INC.':
            case 'TOYOTA MOTOR MANUFACTURING DE BAJA CALIFORNIA':
            case 'TOYOTA MOTOR EUROPE':
                return 'Toyota';
            // Ford
            case 'FORD MOTOR COMPANY':
            case 'FORD MOTOR MANUFACTURING LTD (UK)':
            case 'FORD MOTOR COMPANY OF CANADA':
            case 'FORD MOTOR (SOUTH AFRICA)':
                return 'Ford';
            // Nissan
            case 'NISSAN NORTH AMERICA, INC.':
            case 'NISSAN MOTOR CO., LTD.':
            case 'NISSAN MEXICANA, S.A. DE C.V.':
                return 'Nissan';
            // General Motors (Chevrolet, GMC, etc.)
            // case 'GENERAL MOTORS LLC':
            // case 'GENERAL MOTORS OF CANADA COMPANY':
            // case 'GENERAL MOTORS MEXICO':
            // case 'CHEVROLET MOTOR COMPANY':

            // BMW
            // case 'BAYERISCHE MOTOREN WERKE AG (BMW)':
            // case 'BMW MANUFACTURING CO., LLC (USA)':
            // case 'BMW OF NORTH AMERICA, LLC':

            // Volkswagen
            // case 'VOLKSWAGEN AG':
            // case 'VOLKSWAGEN DE MEXICO S.A. DE C.V.':
            // case 'VOLKSWAGEN GROUP OF AMERICA':

            // Hyundai
            // case 'HYUNDAI MOTOR COMPANY':
            // case 'HYUNDAI MOTOR MANUFACTURING ALABAMA, LLC':

            // Mercedes-Benz
            // case 'DAIMLER AG (MERCEDES-BENZ)':
            // case 'MERCEDES-BENZ U.S. INTERNATIONAL, INC.':
            // case 'MERCEDES-BENZ CANADA, INC.':

            // Tesla
            // case 'TESLA, INC.':
            // case 'TESLA MOTORS CANADA ULC':

            // Subaru
            // case 'SUBARU CORPORATION':
            // case 'SUBARU OF INDIANA AUTOMOTIVE, INC.':
        }

        const makes = ['Honda', 'Toyota', 'Ford', 'Nissan'];
        for (const temp of makes) {
            if (make?.startsWith(temp.toUpperCase())) {
                return temp;
            }
        }

        return undefined;
    }

    private getMake(data: VINDecoderData, allowedVehicles: AllowedVehicle[]): string | undefined {
        const make = this.getMakeFromVINDecoderData(data);
        const allowedVehicle = data.model && allowedVehicles.find(x => `${x.model}`.startsWith(data.model!));
        if (!allowedVehicle) {
            return make;
        }

        switch (data.make?.toUpperCase()) {
            case 'GENERAL MOTORS LLC':
                return ['Chevrolet', 'GMC'].includes(allowedVehicle.make) ? allowedVehicle.make : make;
            case 'FCA US LLC':
                return ['RAM', 'Jeep'].includes(allowedVehicle.make) ? allowedVehicle.make : make;
        }

        return make;
    }

    private getModel(data: VINDecoderData, allowedVehicles: AllowedVehicle[], make?: string): string | undefined {
        const allowedVehicle = allowedVehicles.find(x => x.make === make && x.model === data.model);
        return allowedVehicle?.model;
    }
}
